<template>
  <div class="px-2 mt-1">
    <header-slot />
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: $route.meta.create_mof }"
        exact
        exact-active-class="active"
        :link-classes="['px-3 h-full', bgTabsNavs]"
      >
        CREATE MOF
      </b-nav-item>
      <b-nav-item
        v-if="isRRHHUser"
        :to="{ name: $route.meta.review_mof }"
        exact
        exact-active-class="active"
        :link-classes="['px-3 h-full', bgTabsNavs]"
      >
        REVIEW MOF
        <b-badge v-if="counter" variant="danger" pill class="ml-1">
          {{ counter }}
        </b-badge>
      </b-nav-item>

      <b-nav-item
        v-if="isCeo"
        :to="{ name: $route.meta.review_mof_by_ceo }"
        exact
        exact-active-class="active"
        :link-classes="['px-3 h-full', bgTabsNavs]"
      >
        REVIEW MOF BY CEO
        <b-badge v-if="counter" variant="danger" pill class="ml-1">
          {{ counter }}
        </b-badge>
      </b-nav-item>

      <b-nav-item
        v-if="isCeo && isManagment"
        :to="{ name: $route.meta.review_approved }"
        exact
        exact-active-class="active"
        :link-classes="['px-3 h-full', bgTabsNavs]"
      >
        APPROVED MOF
      </b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    tab() {
      return this.$route.meta.tab;
    },

    ...mapGetters({
      currentUser: "auth/currentUser",
      counter: "ManualsOfFunctions/G_MOF_COUNT",
    }),

    isCeo() {
      return this.currentUser.role_id === 1;
    },

    isRRHHUser() {
      return this.currentUser.main_module === 17;
    },
    isManagment() {
      return this.currentUser.modul_id === this.$route.meta.moduleOfTab;
    },
  },

  async mounted() {
    const params = {
      module_id: this.currentUser.main_module,
    };

    await this.A_COUNTER_PENDING(params);
  },

  methods: {
    ...mapActions({
      A_COUNTER_PENDING: "ManualsOfFunctions/A_GET_MOF_COUNT",
    }),
  },
};
</script>
